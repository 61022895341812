<template lang="pug">
    div.app-container
        //- 查询操作
        div.filter-container
            el-button.filter-item(v-permission="['store:store:create']" type="primary" icon="el-icon-edit" @click="onCreate") 添加店铺

        //- 查询结果 
        el-table(v-loading="listLoading" :data="list" size="small" element-loading-text="正在查询中。。。" border fit highlight-current-row)
            el-table-column(align="left" label="店铺名称" prop="name" sortable width="200px")
            el-table-column(align="left" label="店铺对应邮箱" prop="email" sortable width="200px")
            el-table-column(align="left" label="店铺运营人员" prop="user_ids")
                template(slot-scope="scope")
                    el-tag(v-for="user in scope.row.user_ids" :key="user").ml-5 {{user}}
            el-table-column(align="center" label="操作" width="140px").small-padding.fixed-width
                template(slot-scope="scope")
                    el-link(v-permission="['sku:sku:edit']" type="primary" size="mini" @click="onUpdate(scope.row)") 编辑
                    el-link.opt-link(v-permission="['sku:sku:delete']" type="primary" size="mini" @click="onDelete(scope.row)") 删除

        //- 分页
        pagination(v-show="total>0" :total="total"  :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList")

        //- 弹出层 - 修改sku
        el-dialog(:visible.sync="dialogForm" :title="formMode=='edit'?'修改店铺信息':'新增店铺信息'").pupup-form
          el-form(:inline="true" :model="form" label-width="120px" ref="form" :rules="formRules" )
            el-form-item(label="店铺名称" prop="name")
              el-input(v-model="form.name" :readonly="formMode=='edit'")
            el-form-item(label="店铺对应邮箱" prop="email")
              el-input(v-model="form.email")
            el-form-item(label="店铺运营人员" prop="user_ids")
              el-select(v-model="form.user_ids" multiple placeholder="请选择")
                el-option(v-for="user in userOptions" :key="user.label" :label="user.label" :value="user.value")


          div(slot="footer").dialog-footer
            el-button(@click="onUpdateCancel") 取消
            el-button(v-if="this.formMode=='edit'" v-permission="['store:store:edit']" :loading="form.loading" @click="onUpdateSave" type="primary") 修改
            el-button(v-else v-permission="['store:store:create']" :loading="form.loading" @click="onCreateSave" type="primary") 新增

</template>

<script>
import * as store from "../../api/store";
import { getUserOptions } from "../../api/system";

import Pagination from "@/components/pagination"; // Secondary package based on el-pagination

export default {
  name: "Storelist",
  components: { Pagination },
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      formMode: "create",
      listQuery: {
        page: 1,
        limit: 20,
        keyword: undefined,
        sort: "creation_date",
        order: "desc"
      },
      dialogForm: false,
      userOptions: [],
      form: {},
      formRules: {
        name: {
          required: true,
          message: "店铺名称",
          trigger: "change"
        },
        email: {
          type: "email",
          message: "输入正确的邮箱",
          trigger: "change"
        }
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getUserOptions() {
      const resp = await getUserOptions();
      this.userOptions = resp.data.data;
    },
    async getList() {
      this.listLoading = true;
      try {
        const resp = await store.getStoreList(this.listQuery);
        this.list = resp.data.data.items;
        this.total = resp.data.data.total;
        this.listLoading = false;
      } catch (error) {
        this.listLoading = false;
      }
    },
    async onCreate() {
      this.formMode = "create";
      await this.getUserOptions();
      this.form = {};
      this.dialogForm = true;
      this.$refs.form && this.$refs.form.resetFields();
    },
    async onCreateSave() {
      try {
        await this.$refs.form.validate();
        const res = await store.createStore(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `店铺:${this.form.name} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onUpdate(row) {
      this.formMode = "edit";
      await this.getUserOptions();
      const res = await store.getStoreByPK({ str_id: row.str_id });
      this.form = res.data.data;
      this.dialogForm = true;
      this.$refs.form.resetFields();
    },
    onUpdateCancel() {
      this.dialogForm = false;
    },
    async onUpdateSave() {
      try {
        await this.$refs.form.validate();
        const res = await store.updateStoreByPK(this.form);
        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `店铺:${this.form.name} 信息修改成功`
          });
          this.dialogForm = false;
          this.getList();
        }
      } catch (error) {
        return false;
      }
    },
    async onDelete(row) {
      try {
        // 询问是否进行
        await this.$confirm(
          "此操作将删除店铺:" + row.name + ", 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        );

        // 执行操作
        const res = await store.deleteStoreByPK(row);

        if (res.data.errno == 200) {
          // 提示操作成功
          this.$notify.success({
            title: "成功",
            message: `店铺:${row.asin} 信息删除成功`
          });
        }
        this.getList();
      } catch (e) {
        return false;
      }
    },
    onFilter() {
      this.listQuery.page = 1;
      this.getList();
    }
  }
};
</script>
